import React from 'react';
import { IconName } from '@curology/ui-components-web/react';
import { NavBannerProps } from '..';
import { LOCAL_COLORS } from 'styles/constants';

export const POP_REDESIGN_BANNER_CONTENT: NavBannerProps = {
  backgroundColor: LOCAL_COLORS.surfaceOrange,
  textColor: LOCAL_COLORS.surfaceDarkBold,
  iconName: IconName.Gift,
  text: <React.Fragment>Get 50% off your first box</React.Fragment>,
};

export const getBannerPropsFromLdFlagValue = (
  curologySiteIntroOfferBannerCopy?: string,
) => {
  if (curologySiteIntroOfferBannerCopy) {
    const iconRegex = /\[icon:(.*?)\]/;
    const match = curologySiteIntroOfferBannerCopy.match(iconRegex);
    let iconName;

    if (match && match[1]) {
      Object.keys(IconName).forEach((prop) => {
        const iconNameValue = IconName[prop as keyof typeof IconName];

        if (match[1] === iconNameValue) {
          iconName = iconNameValue;
        }
      });
    }

    const text = curologySiteIntroOfferBannerCopy.replace(iconRegex, '');

    return {
      ...POP_REDESIGN_BANNER_CONTENT,
      iconName,
      text: <React.Fragment>{text}</React.Fragment>,
    };
  }

  return POP_REDESIGN_BANNER_CONTENT;
};
