// Use a flag value other than undefined here to override for local testing (see src/utils/featureFlagsAndMetrics::getFlagOverride)
export type FlagValue =
  | string
  | boolean
  | number
  | ChiaWelcomeOfferFlagValue
  | null
  | undefined;

export type ChiaWelcomeOfferFlagValue = {
  altText: string;
  bannerText: string;
  code: string;
  percentageOff: string;
  percentageOffActual: string;
  price: string;
  titleText: string;
};

export type LaunchDarklyFlagNameType =
  | 'body-cleanse-in-shop-nav'
  | 'curology-promotions-welcome-offer-chia'
  | 'curology-sunscreen-giveaway'
  | 'curology-gift-with-purchase'
  | 'curology-site-intro-offer-banner-copy';

export type DashboardEcomRoutineAddonsExperimentCohort =
  | 'control'
  | 'variant'
  | undefined;

type LaunchDarklyFlagStates = {
  [key in LaunchDarklyFlagNameType]: FlagValue;
};

export const LD_FLAGS: LaunchDarklyFlagStates = {
  'body-cleanse-in-shop-nav': false,
  'curology-promotions-welcome-offer-chia': undefined,
  'curology-sunscreen-giveaway': false,
  'curology-gift-with-purchase': undefined,
  'curology-site-intro-offer-banner-copy': undefined,
};
